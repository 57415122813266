// We need to import the CSS so that webpack will load it.
// The MiniCssExtractPlugin is used to separate it out into
// its own CSS file.
import './navbar.js'

// webpack automatically bundles all modules in your
// entry points. Those entry points can be configured
// in "webpack.config.js".
//
// Import dependencies
//
import "phoenix_html"

// Import local files
//
// Local files can be imported directly using relative paths, for example:
// import socket from "./socket"
import { Socket } from "phoenix"
import {LiveSocket} from "phoenix_live_view"

const Hooks = {
  GPSTrack: {
    mounted(){
      const watchID = navigator.geolocation.watchPosition((position) => {
        this.el.dataset.watchID = watchID;
        this.pushEvent('update_position', {
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
          accuracy: position.coords.accuracy,
          heading: position.coords.heading,
          speed: position.coords.speed,
        });
      }, error => {
        console.log(error)
        this.pushEvent('gps_error', {
          code: error.code,
          message: error.message
        });
      });
    },
    destroyed(){
      const watchID = this.el.dataset.watchID;
      if (watchID){
        navigator.geolocation.clearWatch(watchID);
        this.el.dataset.watchID = undefined;
      }
    }
  }
}
let csrfToken = document.querySelector("meta[name='csrf-token']").getAttribute("content");
let liveSocket = new LiveSocket("/live", Socket, { params: { _csrf_token: csrfToken }, hooks: Hooks });
liveSocket.enableDebug()
liveSocket.connect()
